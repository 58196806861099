/* Utilities */

.grid-margin {
  margin-bottom: $card-spacing-y;
}

.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;

  &.rounded-10 {
    border-radius: 10px;
  }
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 35px;
  height: 35px;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  >.card {
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;

  &.ps-4 {
    padding-left: 30px !important;
  }
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-normal {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;

    }
  }

  .text-avatar {
    @extend .bg-inverse-primary;
    color: $primary;
    font-size: 11px;
    font-weight: 600;
  }
}

.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.aligner-wrapper {
  position: relative;

  .absolute {
    position: absolute;

    &.absolute-center {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }
  }
}

.v-strock-2 {
  width: 3px;
}

.min-width-cell {
  min-width: 110px;
}

.font-weight-medium {
  font-weight: 500;
}

.media {
  display: flex;
}

.settings-nav-bottom {
  position: fixed;
  bottom: 10px;
  width: 220px;
}

.h-250 {
  height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.list.active {
  background: #ffe2e2;
  box-shadow: 0 3px 4px #c2c2c2;
}

.list {
  .dropdown {
    .dropdown-toggle:after {
      content: none;
    }
  }
}

.z-0 {
  z-index: 0 !important;
}

.shadow {
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
}

.shadow-light {
  box-shadow: 0 0 5px rgb(154 154 154 / 43%) !important;
}

.border-radius-5 {
  border-radius: 5px;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  margin: 0;
}

.MuiTypography-root {
  font-family: Manrope, sans-serif;
}

.zoom-on-hover {
  transition: transform 0.3s ease-in-out;
  /* Smooth transition effect */
}

.zoom-on-hover:hover {
  transform: scale(1.05);
  /* Zoom in 1.1 times */
}

select {
  &.form-control {
    color: #000 !important;
  }
}

.bg-red {
  background: #ffdfda;
}

.bg-orange {
  background: #fce4bd;
}


.react-images__view-image.react-images__view-image--isModal {
  height: 100vh;
}

tr.MuiTableRow-root {
  height: inherit !important;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  /* Adjust this value to ensure the element is above the rest */
}

.sidebar {
  position: fixed;

}

.sidebar-icon-only .main-panel {
  margin-left: 70px !important;
}

.main-panel {
  margin-left: 220px;
}


.icon-size {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 1.5rem;
}

.amplify-auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.amplify-auth-header {
  background-image: url('../../assets/images/logo.png'); // Adjust the path accordingly
  background-repeat: no-repeat;
  background-position: center;
  height: 150px; // Adjust this based on your logo's size
  width: 100%;
  margin-bottom: 20px;
}